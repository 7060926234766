import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-3 text-lg text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VocabTile = _resolveComponent("VocabTile")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          ($setup.favorites.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Es wurden noch keine Vokabeln zu den Favoriten hinzugefügt. "))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.favorites, (vocab) => {
            return (_openBlock(), _createBlock(_component_VocabTile, {
              key: vocab.id,
              icon: vocab.icon,
              id: vocab.id,
              translations: vocab.translations
            }, null, 8, ["icon", "id", "translations"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}

import { IonPage, IonContent } from '@ionic/vue'
import VocabTile from '../components/VocabTile.vue'
import { getVocabs } from '@/db'
import { computed } from '@vue/reactivity'
import { settings } from '@/settings'

export default {
  name: 'Favorites',
  components: {
    IonContent,
    IonPage,
    VocabTile
  },
  setup() {
    const vocabs = getVocabs()
    const favorites = computed(() =>
      vocabs.filter((vocab) => settings.value.favorites.includes(vocab.id))
    )

    return {
      favorites
    }
  }
}
